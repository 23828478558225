.fade-in-image {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.rg-text-cell,
.rg-number-cell {
  background-color: white !important;
  border: none !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;;
  border-bottom-color: #efefef !important;
}

.rg-header-cell {
  border: none !important;
  color: white;
  font-size: 1.1rem;
  font-weight: 400;
  background-color: #323436 !important;
  border-bottom-width: 5px !important;
  border-bottom-style: solid !important;;
  border-bottom-color: #5cdb94 !important;
}